<template>
  <v-row>
    <v-col
      v-for="information in informations"
      :key="information.id"
      cols="12"
      sm="4"
      md="4"
    >
      <v-card
        class="mx-auto"
        outlined
      >
        <v-card-title class="text-h5">
          {{ information.ligne_parametrage_agr.libelle_ligneparametrage_agrs }}
        </v-card-title>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4 text-center">
              {{ information.valeurs }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import agr from '@/services/agr.js'
  export default {
    data () {
      return {
        informations: [],
      }
    },
    mounted () {
      this.getInformations()
    },
    methods: {
      getInformations () {
        agr.getInfoCollects(this.$route.params.collect).then(response => {
          this.informations = response.data
        }).catch(err => {
          console.log(err.response.data)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
